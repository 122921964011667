import React from "react";
import Link, { LinkProps as NextLinkProps } from "next/link";
import { UrlObject } from "url";
import { stripUnlisted } from "@/helpers/utils";

interface StoryblokLink {
  id?: string;
  url?: string;
  linktype?: string;
  fieldtype?: string;
  cached_url?: string;
}

interface LinkProps
  extends Omit<NextLinkProps, "href">,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> {
  href: string | StoryblokLink;
  children?: any;
}

export default React.forwardRef(
  ({ href, prefetch, children, ...props }: LinkProps, ref: any) => {
    const sbLinkUrl: UrlObject | string =
      typeof href === "object"
        ? href?.url || (href?.cached_url && `/${href.cached_url}`) || ""
        : href || "";

    return (
      <Link
        href={stripUnlisted(sbLinkUrl)}
        prefetch={prefetch || false}
        {...props}
        ref={ref}
      >
        {children}
      </Link>
    );
  }
);
