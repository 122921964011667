import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { GetAllSettings, getAllSettings } from "@/lib/storyblok-gapi";

type Settings = GetAllSettings["SettingsItems"]["items"][number];

interface Context {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}

const defaultValue = {
  settings: {},
  setSettings: () => {},
} as unknown as Context;

export const SettingsContext = createContext<Context>(defaultValue);

const SettingsContextProvider = ({ children }: PropsWithChildren) => {
  const [settings, setSettings] = useState<Settings>(defaultValue.settings);

  useEffect(() => {
    (async () => {
      const allSettings = await getAllSettings();
      setSettings(allSettings?.SettingsItems?.items[0]);
    })();
  }, []);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
