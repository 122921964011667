import { GetEvents } from "@/lib/storyblok-gapi";
import React, { PropsWithChildren, createContext, useState } from "react";

type Event = GetEvents[number];

interface Data {
  page_class: string;
  event: null | Event;
}

interface Context {
  data: Data;
  setData: (data: any) => void;
}

const defaultValue: Context = {
  data: {
    page_class: "",
    event: null,
  },
  setData: () => {},
};

export const PageContext = createContext(defaultValue);

const PageContextProvider = ({
  children,
  pageProps,
}: PropsWithChildren<{ pageProps?: { event?: null | Event } }>) => {
  const [data, setData] = useState<Data>(() => ({
    ...defaultValue.data,
    event: pageProps?.event || null,
  }));

  return (
    <PageContext.Provider value={{ data, setData }}>
      {children}
    </PageContext.Provider>
  );
};

export function usePageContext() {
  const context = React.useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePageContext must be used within a PageContextProvider");
  }
  return context;
}

export default PageContextProvider;
