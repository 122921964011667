import React from "react";
import dynamic from "next/dynamic";
import Link from "@/components/Link";
const LogoColour = dynamic(import("./assets/logo.svg"));
const LogoMono = dynamic(import("./assets/logo-mono.svg"));
import styles from "./logo.module.scss";

interface Props {
  url?: string;
  mono?: boolean;
}

const Logo = ({ url = "/", mono }: Props) => {
  const Logo = mono ? LogoMono : LogoColour;

  return (
    <Link className={styles.logo} href={url}>
      <Logo />
    </Link>
  );
};

export default Logo;
