import React, { createContext, useContext, useEffect, useState } from "react";
import Logo from "./Logo/logo";
import dynamic from "next/dynamic";
const GlobalHeader = dynamic(
  import("@/components/Header/GlobalHeader/global-header")
);
const Nav = dynamic(import("./Nav/nav"));
import styles from "./header.module.scss";

interface Context {
  globalHeaderOpen: boolean;
  setGlobalHeaderOpen: (open: boolean) => void;
}

export const HeaderContext = createContext<Context>({
  globalHeaderOpen: false,
  setGlobalHeaderOpen: () => {},
});

const Header = () => {
  const [globalHeaderOpen, setGlobalHeaderOpen] = useState(false);
  const duration = 0.6;

  return (
    <HeaderContext.Provider value={{ globalHeaderOpen, setGlobalHeaderOpen }}>
      <header className={styles.siteHeader}>
        <GlobalHeader duration={duration} className={styles.globalHeader} />
        <div className={`${styles.proCyclingHeader__inner} layout-grid`}>
          <Logo />
          <Nav
            close={globalHeaderOpen}
            duration={duration}
            className={styles.proCyclingNav}
          />
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

export default Header;
