import {
  CoachStoryblok,
  EventStoryblok,
  SettingsStoryblok,
} from "@/types/component-types-sb";
import { getOrder } from "../helpers/utils";
import { isAfter, parseISO } from "date-fns";

async function fetchAPI(
  query: string,
  {
    variables,
    version,
  }: { variables?: Record<string, unknown>; version?: string } = {}
) {
  const res = await fetch("https://gapi.storyblok.com/v1/api", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN || "",
      Version: version || process.env.NEXT_PUBLIC_CONTENT_VERSION || "",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  console.info(
    `Getting data with ${
      version || process.env.NEXT_PUBLIC_CONTENT_VERSION
    } version.`
  );

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }

  return json.data;
}

export const getBlogStories = async (
  perPage = 100
): Promise<GetBlogStories> => {
  return await fetchAPI(
    `
    query getBlogStories ($perPage: Int) {
      BlogItems(per_page: $perPage, starts_with: "blog", sort_by: "first_published_at:desc", is_startpage: "false") {
         items {
          name
          content {
            article_image {
             filename
             focus
             alt
           }
           _uid
          }
          name
          first_published_at
          full_slug
          id
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {
        perPage,
      },
    }
  );
};

export interface GetBlogStories {
  BlogItems: {
    items: {
      name: string;
      content: {
        article_image: {
          id: number;
          name: string;
          filename: string;
          focus: string;
          alt: string;
        };
        _uid: string;
      };
      first_published_at: string;
      full_slug: string;
      id: string;
    }[];
  };
}

export const getContentHubStories = async (
  perPage = 100
): Promise<GetContentHubStories> => {
  return await fetchAPI(
    `
    query getContentHubStories ($perPage: Int) {
      ProtectedpageItems(per_page: $perPage, sort_by: "first_published_at:desc", is_startpage: "false") {
         items {
          name
          content {
            body
            show_for_role
            article_image {
              filename
              focus
              alt
            }
          }
          name
          first_published_at
          full_slug
          slug
          id
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {
        perPage,
      },
    }
  );
};

export interface GetContentHubStories {
  ProtectedpageItems: {
    items: {
      name: string;
      content: {
        body: any[];
        show_for_role: string;
        article_image: {
          filename: string;
          focus: string;
          alt: string;
        };
      };
      first_published_at: string;
      full_slug: string;
      slug: string;
      id: string;
    }[];
  };
}

export async function getLatestArticleStories(): Promise<GetLatestArticleStories> {
  return await fetchAPI(`{
  ArticleItems(per_page: 100, sort_by: "first_published_at:desc", is_startpage: "false") {
       total
       items {
        content {
          _uid
          article_image {
           filename
           focus
           alt
         }
        }
        name
        first_published_at
        full_slug
        id
      }
    }
  }
`);
}

export interface GetLatestArticleStories {
  ArticleItems: {
    total: number;
    items: {
      content: {
        _uid: string;
        article_image: {
          filename: string;
          focus: string;
          alt: string;
        };
      };
      name: string;
      first_published_at: string;
      full_slug: string;
      id: string;
    }[];
  };
}

export async function getNextWebinar(): Promise<GetNextWebinar> {
  const webinars = await fetchAPI(
    `{
  ContenthubpageItem(id: "my-coaching/content-hub/webinars") {
    id
    content {
      videos
    }
  }
}
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );

  const nextWebinar = webinars?.ContenthubpageItem?.content?.videos
    ?.filter((video: any) => isAfter(parseISO(video?.date), new Date()))
    ?.sort((a: any, b: any) =>
      a?.date < b?.date ? -1 : a.date > b.date ? 1 : 0
    )[0];

  return nextWebinar || {};
}

export interface GetNextWebinar {
  date: string;
  title: string;
  video: string;
}

export async function getCurrentPaths({
  name = "BlogItems",
  starts_with = "",
}): Promise<GetCurrentPaths> {
  console.log({ name });
  return await fetchAPI(
    `
    query getCurrentPaths($slug: String) {
      ${name}(per_page: 100, starts_with: $slug) {
        total
        items {
          full_slug
          path
          slug
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {
        slug: starts_with,
      },
    }
  );
}

type GetCurrentPathsName =
  | "BlogItems"
  | "PageItems"
  | "EventItems"
  | "CoachItems";
export interface GetCurrentPathsValue {
  total: number;
  items: {
    full_slug: string;
    path: string;
    slug: string;
  }[];
}

export type GetCurrentPaths = Record<GetCurrentPathsName, GetCurrentPathsValue>;

export async function getAllStories(): Promise<GetAllStories> {
  return await fetchAPI(
    `
    {
  PageItems(per_page: 100) {
    total
    items {
      full_slug,
      path,
      slug
    }
  }
}
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );
}

export type GetAllStories = {
  PageItems: {
    total: number;
    items: {
      full_slug: string;
      path: string;
      slug: string;
    }[];
  };
};

export async function getAllRootStories(): Promise<GetAllRootStories> {
  return await fetchAPI(
    `
    {
  PageItems(per_page: 100) {
    total
    items {
      full_slug,
      path,
      slug
    }
  }
}
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );
}

export interface GetAllRootStories {
  PageItems: {
    total: number;
    items: {
      full_slug: string;
      path: string;
      slug: string;
    }[];
  };
}

export async function getAllSettings(): Promise<GetAllSettings> {
  return await fetchAPI(
    `
        {
  SettingsItems {
    total
    items {
      id
      name
      created_at
      published_at
      uuid
      slug
      full_slug
      is_startpage
      parent_id
      group_id
      content {
        _editable
        _uid
        component
        global_nav
        main_nav
        main_nav_cta_link{cachedUrl}
        newsletter_sign_up_text
        newsletter_success_text
        instagram
        twitter
        facebook
        youtube
        contact_email
        seo_meta_fields
      }
    }
  }
}

        `,
    {
      variables: {},
    }
  );
}

export interface GetAllSettings {
  SettingsItems: {
    total: number;
    items: {
      id: string;
      name: string;
      created_at: string;
      published_at: string;
      uuid: string;
      slug: string;
      full_slug: string;
      is_startpage: boolean;
      parent_id: string;
      group_id: string;
      content: {
        _editable: string;
        _uid: string;
        component: SettingsStoryblok["component"];
        global_nav: SettingsStoryblok["global_nav"];
        main_nav: SettingsStoryblok["main_nav"];
        main_nav_cta_link: {
          cachedUrl: string;
        };
        newsletter_sign_up_text: SettingsStoryblok["newsletter_sign_up_text"];
        newsletter_success_text: SettingsStoryblok["newsletter_success_text"];
        instagram: SettingsStoryblok["instagram"];
        twitter: SettingsStoryblok["twitter"];
        facebook: SettingsStoryblok["facebook"];
        youtube: SettingsStoryblok["youtube"];
        contact_email: SettingsStoryblok["contact_email"];
        seo_meta_fields: SettingsStoryblok["seo_meta_fields"];
        show_popup?: boolean;
      };
    }[];
  };
}

export async function getAllEvents(): Promise<GetAllEvents> {
  const events = await fetchAPI(
    `
    {
    EventItems(per_page: 100, sort_by: "first_published_at:desc", is_startpage: "false") {
       items {
          content {
            index_image { filename, focus, alt }
            index_pre_title
            index_title
            index_button_style
            index_text_style
            start_date
            end_date
            location
            sold_out
            last_spots
          }
          uuid
          full_slug
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );

  return events?.EventItems?.items || [];
}

export type GetAllEvents = {
  content: {
    index_image: {
      filename: string;
      focus: string;
      alt: string;
    };
    index_pre_title: EventStoryblok["index_pre_title"];
    index_title: EventStoryblok["index_title"];
    index_button_style: EventStoryblok["index_button_style"];
    index_text_style: EventStoryblok["index_text_style"];
    start_date: EventStoryblok["start_date"];
    end_date: EventStoryblok["end_date"];
    location: EventStoryblok["location"];
    sold_out: EventStoryblok["sold_out"];
    last_spots: EventStoryblok["last_spots"];
  };
  uuid: string;
  full_slug: string;
}[];

export async function getAllCoaches(): Promise<GetAllCoaches> {
  const coaches = await fetchAPI(
    `
    {
    CoachItems(per_page: 100, sort_by: "first_published_at:desc", is_startpage: "false") {
       items {
        id
        content {
          image
          intro
          languages
          location
          name
          nationality
          body
          coach_type
        }
        uuid
        full_slug
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );

  return coaches?.CoachItems?.items || [];
}

export type GetAllCoaches = {
  id: string;
  content: {
    image: string;
    intro: string;
    languages: string;
    location: string;
    name: string;
    nationality: string;
    body: string;
    coach_type: string;
  };
  uuid: string;
  full_slug: string;
}[];

export async function getRandomCoaches(num: number): Promise<GetRandomCoaches> {
  const coaches = await fetchAPI(
    `
    {
    CoachItems(per_page: 100, sort_by: "first_published_at:desc", is_startpage: "false") {
       items {
        id
        content {
          image
          intro
          languages
          location
          name
          nationality
          body
          coach_type
        }
        uuid
        full_slug
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );

  const { items } = coaches.CoachItems || [];

  return items.sort(() => 0.5 - Math.random()).slice(0, num);
}

export type GetRandomCoaches = {
  content: {
    image: CoachStoryblok["image"];
    intro: CoachStoryblok["intro"];
    languages: CoachStoryblok["languages"];
    location: CoachStoryblok["location"];
    name: CoachStoryblok["name"];
    nationality: CoachStoryblok["nationality"];
    body: CoachStoryblok["body"];
    coach_type: CoachStoryblok["coach_type"];
  };
  uuid: string;
  _uid: string;
  full_slug: string;
  id: string;
}[];

export const getCoaches = async ({
  uuids,
}: {
  uuids: string;
}): Promise<GetCoaches> => {
  const coaches = await fetchAPI(
    `
    query getCoaches($uuids: String) {
      CoachItems(by_uuids: $uuids) {
      items {
        content {
          image
          intro
          languages
          location
          name
          nationality
          body
          coach_type
        }
        uuid
        full_slug
      }
    }
  }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {
        uuids,
      },
    }
  );

  return coaches?.CoachItems?.items || [];
};

export type GetCoaches = {
  content: {
    image: CoachStoryblok["image"];
    intro: CoachStoryblok["intro"];
    languages: CoachStoryblok["languages"];
    location: CoachStoryblok["location"];
    name: CoachStoryblok["name"];
    nationality: CoachStoryblok["nationality"];
    body: CoachStoryblok["body"];
    coach_type: CoachStoryblok["coach_type"];
  };
  uuid: string;
  _uid: string;
  full_slug: string;
  id: string;
}[];

export async function isUnlisted({ slug }: { slug: string }): Promise<boolean> {
  const pages = await fetchAPI(
    `
    {
  PageItems(per_page: 100, starts_with: "unlisted/${slug}") {
    items {
      slug,
    }
  }
}
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {},
    }
  );

  return pages?.PageItems?.items && pages?.PageItems?.items.length > 0;
}

export const getEvents = async ({
  uuids,
}: {
  uuids: string;
}): Promise<GetEvents> => {
  const events = await fetchAPI(
    `
    query getEvents($uuids: String) {
      EventItems(by_uuids: $uuids) {
        items {
          content {
            index_image { filename, focus, alt }
            index_pre_title
            index_title
            index_button_style
            index_text_style
            start_date
            end_date
            location
            sold_out
            last_spots
            price
            payment_link {
              url
            }
          }
          uuid
          full_slug
        }
      }
    }
`,
    {
      version: process.env.NEXT_PUBLIC_CONTENT_VERSION,
      variables: {
        uuids,
      },
    }
  );

  return events?.EventItems?.items;
};

export type GetEvents = {
  content: {
    index_image: {
      filename: string;
      focus: string;
      alt: string;
    };
    index_pre_title: EventStoryblok["index_pre_title"];
    index_title: EventStoryblok["index_title"];
    index_button_style: EventStoryblok["index_button_style"];
    index_text_style: EventStoryblok["index_text_style"];
    start_date: EventStoryblok["start_date"];
    end_date: EventStoryblok["end_date"];
    location: EventStoryblok["location"];
    sold_out: EventStoryblok["sold_out"];
    last_spots: EventStoryblok["last_spots"];
    price: EventStoryblok["price"];
    payment_link: {
      url: string;
    };
  };
  _uid: string;
  uuid: string;
  full_slug: string;
}[];

export const generateCoaches = async ({
  coachItems,
}: {
  coachItems: { coach: string }[];
}) => {
  const coaches = await getCoaches({
    uuids: coachItems.map((coach) => coach.coach).join(","),
  });

  return coaches.sort((a, b) => {
    const order = getOrder(coachItems, "coach");
    return order.indexOf(a.uuid) - order.indexOf(b.uuid);
  });
};

export const generateEvents = async <T extends { event: string }[]>({
  eventItems,
}: {
  eventItems: T;
}) => {
  const events = await getEvents({
    uuids: eventItems.map((event) => event.event).join(","),
  });

  return events.sort((a, b) => {
    const order = getOrder(eventItems, "event");
    return order.indexOf(a.uuid) - order.indexOf(b.uuid);
  });
};
