import React, { useState, useEffect, useContext, forwardRef } from "react";
import { BreakpointProvider } from "@/context/BreakpointContext";
import PageContextProvider, { PageContext } from "../context/PageContext";
import SettingsContextProvider from "../context/SettingsContext";
import Header from "./Header/header";
import { useRouter } from "next/router";
import classnames from "classnames";
import dynamic from "next/dynamic";

const Footer = dynamic(() => import("./Footer/footer"));

interface Props {
  children: React.ReactNode;
  pageProps: any;
}

const Layout = (props: Props) => {
  const router = useRouter();
  const [path, setPath] = useState(router.asPath);

  useEffect(() => {
    setPath(router.asPath);
  }, [router.asPath]);

  const { children } = props;

  return (
    <PageContextProvider pageProps={props.pageProps}>
      <SettingsContextProvider>
        <BreakpointProvider>
          <Site>{children}</Site>
        </BreakpointProvider>
      </SettingsContextProvider>
    </PageContextProvider>
  );
};

const Site = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    className?: string;
  }
>((props, ref) => {
  const { children, className } = props;
  const pageContext = useContext(PageContext);
  const pageClass = pageContext?.data?.page_class;
  const [themeClass, setThemeClass] = useState("");
  useEffect(() => {
    setThemeClass(pageClass);
  }, [pageClass]);
  const classes = classnames("layout-site", themeClass, className);

  return (
    <div className={classes} ref={ref}>
      <Header />
      {children}
      <Footer />
    </div>
  );
});

export default Layout;
