import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";

const initialContext = {
  currentBp: "",
};

const BreakpointContext = createContext(initialContext);

const BreakpointProvider = ({ children }: PropsWithChildren) => {
  const [dimensions, setDimensions] = useState(initialContext);

  useEffect(() => {
    const queries = {
      s: window.matchMedia("(max-width: 768px)"),
      m: window.matchMedia("(max-width: 1024px)"),
      l: window.matchMedia("(max-width: 1264px)"),
      xl: window.matchMedia("(min-width: 1264px)"),
    };
    const currentSize = Object.entries(queries)
      .filter(([_, query]) => query.matches)
      .map(([size, query]) => size);
    setDimensions({ currentBp: currentSize[0] });

    const updateDimensions = (currentBp: string | Event) => {
      if (typeof currentBp !== "string") {
        return;
      }
      setDimensions({ currentBp });
    };

    queries.s.addEventListener("change", () => updateDimensions("s"));
    queries.m.addEventListener("change", () => updateDimensions("m"));
    queries.l.addEventListener("change", () => updateDimensions("l"));
    queries.xl.addEventListener("change", () => updateDimensions("xl"));

    return () => {
      queries.s.removeEventListener("change", updateDimensions);
      queries.m.removeEventListener("change", updateDimensions);
      queries.l.removeEventListener("change", updateDimensions);
      queries.xl.removeEventListener("change", updateDimensions);
    };
  }, []);

  return (
    <BreakpointContext.Provider value={dimensions}>
      {children}
    </BreakpointContext.Provider>
  );
};

/*
  Example usage:
  bpInRange({currentBp: 's', bp: 'm', range: 'down'}) => true
 */

export const bpInRange = ({
  currentBp,
  bp,
  range,
}: {
  currentBp: string;
  bp: string;
  range: string;
}) => {
  const bps = ["s", "m", "l", "xl"];

  if (!bps.includes(bp)) throw "Breakpoint is not supported";

  const currIndex = bps.indexOf(bp);

  switch (range) {
    case "up":
      return bps.slice(currIndex).includes(currentBp);
    case "down":
      return bps.slice(0, currIndex + 1).includes(currentBp);
    case "only":
      return currentBp === bp;
    default:
      throw "Only 'up', 'down', 'only' ranges are supported";
  }
};

export { BreakpointContext, BreakpointProvider };
