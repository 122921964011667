import { format, parseISO } from "date-fns";

export const formatDate = (stamp: string) =>
  stamp ? format(new Date(parseISO(stamp)), "MMMM d, yyyy") : "";

export const getOrder = <T extends any[]>(itemsArr: T, type: string) => {
  return itemsArr.reduce((acc, curr) => {
    acc.push(curr[type]);
    return acc;
  }, []);
};

export const aspectToPadding = (aspectStr?: string | null) => {
  if (!aspectStr) return undefined;
  const arr = aspectStr.split("/");
  return (Number(arr[1]) / Number(arr[0])) * 100;
};

export const stripUnlisted = (url: string) => url?.replace("unlisted/", "");

export const randomInteger = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const anyToKebab = (s: string) =>
  s
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((l) => l.toLowerCase())
    .join("-");

export const basicLoader = ({ src, width }: { src: string; width: number }) =>
  `${src}?w=${width}`;

export const toTitleCase = (str: string) =>
  str
    .replace(/-/g, " ")
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
    .join(" ");

export const signInCallbackUrl = (tier: string) => {
  return tier === "FOUNDATIONS" ? "/my-coaching" : "/training-plans";
};
